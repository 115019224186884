import React from 'react';
import { useSettings } from '@lib/hooks/use-settings';
import Image from 'next/image';
import Link from 'next/link';

const PromoCard = () => {
  const {
    promoLink,
    promoBgColor,
    promoBannerUrl: promoImageUrl,
  } = useSettings();

  return (
    <Link href={promoLink} passHref>
      <a
        style={{ backgroundColor: promoBgColor }}
        className="block relative w-full min-h-[244px] shadow-xs hover:shadow-sm group rounded-xl cursor-pointer"
      >
        <Image
          src={promoImageUrl}
          alt="promotion"
          layout="fill"
          objectFit="contain"
          objectPosition="center"
          className="w-full h-auto"
        />
      </a>
    </Link>
  );
};

export default PromoCard;
